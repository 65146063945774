/*Header */
/*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 80) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*Open Menu*/
$(".menu_open button").on('click', function() {
    if($(this).hasClass('is-active')){
        $("body").css('overflow-y','scroll');
        $(this).removeClass('is-active');
        $('.headermenu').removeClass('is-active');
        $('.header_wrapper').removeClass('openmenu');
        $('.menu li').removeClass('aos-animate');
      }
      else{
        $(this).addClass('is-active');
        jQuery("body").css('overflow-y','hidden');
        $('.headermenu').addClass('is-active');
        $('.header_wrapper').addClass('openmenu');
        setTimeout(
        function()
        {
          $( ".menu li" ).each(function( index ) {
                    $(this).addClass('aos-animate');
                    $(this).addClass('aos-init');
          });
      }, 1000);
    }
});


/*Menu Animation*/
$( document ).ready(function() {
  $( ".menu > li" ).each(function( index ) {
      $(this).attr('data-aos','fade-up');
      $(this).attr('data-aos-duration','600');
      $(this).addClass('aos-init');
      setTimeout(function() {$( ".menu li" ).removeClass('aos-animate');  }, 300);
      if(index==0){
          $(this).attr('data-aos-delay',100);
      }else{
        $(this).attr('data-aos-delay',index*150);
      }
  });
});

        /*Gallery*/
          import Swiper, { Navigation } from 'swiper';
          const gallery_swi = new Swiper(".gallery_swipe", {
            slidesPerView: "1",
            centeredSlides: false,
            navigation: {
             nextEl: '.swiper-button-next',
             prevEl: '.swiper-button-prev',
           }
          });

          /*Slider Rooms Section Homepage*/
          const rooms_swi = new Swiper(".room_swipe", {
            slidesPerView: "3",
            centeredSlides: false,
            breakpoints: {
              0: {
                slidesPerView: "1",
                centeredSlides: false,
              },
              768: {
                slidesPerView: "2",
                centeredSlides: false,
              },
              1025: {
               slidesPerView: "3",
               centeredSlides: false,
             }
            },
            navigation: {
             nextEl: '.swiper-button-next',
             prevEl: '.swiper-button-prev',
           }
          });


const subMenuBtns = document.querySelectorAll('.menu-item-has-children')
subMenuBtns.forEach(btn => {
  btn.addEventListener('click', () => {
    btn.querySelector('.sub-menu').classList.toggle('is-active')
    btn.firstElementChild.classList.toggle('is-active')
    if (window.innerWidth < 768) {
      document.querySelector('.headermenu').classList.toggle('sub-open')
    }
  })
})

if (document.querySelector('.page-template-tmpl_career') && document.querySelector('.wpcf7-file') && document.querySelector('#file-name')) {
  const fileInput = document.querySelector('.wpcf7-file');
  const fileNameSpan = document.getElementById('file-name');
  fileInput.addEventListener('change', (event) => {
    const fileName = event.target.value.split('\\').pop(); // Extract filename
    fileNameSpan.textContent = fileName ? fileName : 'No file selected';
  });
}